import React, { useEffect, useState } from "react";
import { Layout, Tabs } from "antd";
import NewMessage from "./NewMsg";
import DraftMessage from "./DraftMsg";
import SentMessage from "./SentMsg";
import styles from "./BroadcastMessage.module.css";
import { Link, useLocation } from "react-router-dom";
import { getTemplateData } from "../../Adapters/SendMessage";
import CollapsableMenu from "../../components/CollapsableMenu";
import ScheduledMsg from "./ScheduledMsg";
const BrodacastMessage = () => {
    const location = useLocation();
    const from = location?.state?.from;
    const [saveDraftFlag, setsaveDraftFlag] = useState(false);

    const [mainTabKey, setMainTabKey] = useState("");

    const onChange = (key) => {
        setMainTabKey(key);
    };

    const items = [
        {
            key: "1",
            label: `NEW MESSAGE`,
            children: <NewMessage saveDraftFlag={saveDraftFlag} />,
        },
        {
            key: "2",
            label: `SCHEDULED`,
            children: <ScheduledMsg mainTabKey={mainTabKey} />,
        },
        {
            key: "3",
            label: `DRAFTS`,
            children: <DraftMessage mainTabKey={mainTabKey} />,
        },
        {
            key: "4",
            label: `SENT`,
            children: <SentMessage mainTabKey={mainTabKey} />,
        },
    ];

    return (
        <>
            <Layout>
                <CollapsableMenu></CollapsableMenu>
                <div style={{ width: "98%" }}>
                    <h1 className={styles.broadcastHeading}>
                        Broadcast Message
                    </h1>
                    <div className={styles.parentBox}>
                        <Tabs
                            defaultActiveKey="1"
                            items={items}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default BrodacastMessage;
