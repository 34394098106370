import React, { useState, useEffect } from "react";
import {
    Col,
    Row,
    Form,
    Select,
    Modal,
    message,
    TimePicker,
    DatePicker,
} from "antd";
import MultiSubSelectDropDown from "../../../../components/Utils/MultiSubSelectDropDown";
import MultiSelectDropDown from "../../../../components/Utils/MultiSelectDropDown";
import {
    getTemplateData,
    postTemplateData,
} from "../../../../Adapters/SendMessage";
import {
    discardDraft,
    getDepartmentData,
} from "../../../../Adapters/BroadcastmessageCalls";
import { userState } from "../../../../RecoilState/userState";
import { useRecoilState } from "recoil";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import dayjs from "dayjs";
const Basic = ({
    setVariableData,
    draftFlag,
    setActiveTab,
    setTabState,
    setShowEditTabs,
    preFilledDraftData,
    setPrefilledInputValue,
    isTemplateValue,
    setIsTemplateValue,
}) => {
    const [user] = useRecoilState(userState);
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);
    const [language, setLanguage] = useState([]);
    const [template, settemplate] = useState("");
    const [templateID, setTemplateID] = useState("");
    const [arrTemplateID, setArrTemplateID] = useState([]);
    const [selectedDeptID, setselectedDeptID] = useState([]);
    const [departmentDropdownShow, setDepartmentDropdownShow] = useState(false);
    const [genderDropdownShow, setGenderDropdownShow] = useState(false);
    const [langDropdownShow, setLangDropdownShow] = useState(false);
    const [templateResData, setTemplateResData] = useState([]);
    const [allTemplates, setAllTemplates] = useState({});
    const [allDepartments, setAllDepartments] = useState({});
    const [languageSelectedData, setLanguageSelectedData] = useState([]);
    const [genderSelectedData, setGenderSelectedData] = useState([]);
    const [departmentSelectedData, setDepartmentSelectedData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [factorySelectedData, setfactorySelectedData] = useState([]);
    const [factoryDropdownShow, setFactoryDropdownShow] = useState(false);
    const [factoryListIds, setFactoryListIds] = useState([]);
    const [getDepartmentFlag, setGetDepartmentFlag] = useState(false);
    const [factoryData, setFactoryData] = useState([]);
    const today = dayjs();
    const [form] = Form.useForm();
    const [clickedTimes, setClickedTimes] = useState([]);
    const onChangeDate = (date) => {
        form.setFieldsValue({ date });
    };
    const initialValues = {
        template: draftFlag ? preFilledDraftData?.Template : undefined,
        language: languageSelectedData,
        gender: genderSelectedData,
        factory: factorySelectedData,
        date: undefined,
        time: undefined,
        department: null,
    };

    const transformSelectedDeptID = (selectedDeptID) => {
        // Ensure the serializing the obj
        return JSON.stringify(selectedDeptID || {});
    };
    const updateFormValues = () => {
        const transformedDeptID = transformSelectedDeptID(selectedDeptID);
        form.setFieldsValue({
            language: languageSelectedData,
            gender: genderSelectedData,
            factory: factorySelectedData,
            department: transformedDeptID,
        });
    };

    // Call updateFormValues whenever the internal state changes
    useEffect(() => {
        updateFormValues();
    }, [
        languageSelectedData,
        genderSelectedData,
        factorySelectedData,
        selectedDeptID,
    ]);
    const disabledTime = () => {
        const selectedDate = form.getFieldValue("date");
        const isToday = dayjs().isSame(selectedDate, "day");

        const currentHour = dayjs().hour();
        const currentMinute = dayjs().minute();

        const disabledHours = [];
        const disabledMinutes = [];

        // Disable past hours if the selected date is today
        if (isToday) {
            for (let i = 0; i < currentHour; i++) {
                disabledHours.push(i);
            }
        }

        // Disable past minutes if the selected hour is the current hour
        if (isToday && clickedTimes) {
            const hourValue = dayjs(clickedTimes).hour();
            if (hourValue === currentHour) {
                for (let i = 0; i < currentMinute + 15; i++) {
                    disabledMinutes.push(i);
                }
            }

            // Disable specific minutes of the next hour if within 15 minutes of the next hour
            if (currentMinute >= 45) {
                for (let i = 0; i < 15; i++) {
                    disabledMinutes.push(i);
                }
            }
        }

        return {
            disabledHours: () => disabledHours,
            disabledMinutes: (selectedHour) => {
                if (
                    selectedHour === currentHour ||
                    selectedHour === currentHour + 1
                ) {
                    return disabledMinutes;
                }
                return [];
            },
        };
    };
    // Restrict date picker to next 30 days
    const disabledDate = (current) => {
        return (
            current &&
            (current < today.startOf("day") ||
                current > today.add(30, "day").endOf("day"))
        );
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        discardDraftHandler();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const discardDraftHandler = async () => {
        const res = await discardDraft(preFilledDraftData?.draftID);

        message.success(res.data.message);
        setShowEditTabs(false);
    };

    useEffect(() => {
        if (user?.company_fk !== undefined) {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/template`
            );

            queryUrl.searchParams.append("companyID", user?.company_fk);

            queryUrl.searchParams.append("temp_category", "Broadcast Message");

            try {
                getTemplateData(queryUrl).then((res) => {
                    setTemplateResData(res?.data.message_body);

                    setAllDepartments(res.data?.message_body?.departments);

                    setAllTemplates(res?.data.message_body?.templates);
                });
            } catch (error) {
                console.log("error in res");
            }
        }
    }, [draftFlag, user?.company_fk]);
    const gender = ["Male", "Female", "Other", "Not specified"];

    const onFormLayoutChange = (values) => {
        if ("template" in values) {
            settemplate(values.template);
            setIsTemplateValue(!isTemplateValue);
        } else {
            setLanguage(languageSelectedData);
        }
    };

    useEffect(() => {
        factoryDropdownShow && setDepartmentDropdownShow(false);
    }, [factoryDropdownShow]);

    useEffect(() => {
        const result = [];

        // Set language selected data
        draftFlag
            ? setLanguageSelectedData(preFilledDraftData?.language)
            : setLanguageSelectedData(["English"]);

        // Set gender selected data
        draftFlag
            ? setGenderSelectedData(preFilledDraftData?.Genders)
            : setGenderSelectedData([]);

        // Set template IDs
        draftFlag
            ? setArrTemplateID(preFilledDraftData?.templateIDs)
            : setArrTemplateID([]);

        // Fetching the ids of selected factories

        draftFlag
            ? Array.isArray(preFilledDraftData?.Factories)
                ? (() => {
                      const codeArray = preFilledDraftData?.Factories.map(
                          (factory) => factory.Code
                      );

                      setfactorySelectedData(codeArray);
                  })()
                : setfactorySelectedData([])
            : setfactorySelectedData([]);

        setLangDropdownShow(false);
    }, [template]);

    useEffect(() => {
        if (draftFlag) {
            settemplate(preFilledDraftData?.Template);
        }
        if (template && Object.keys(allTemplates).length) {
            var arr = languageSelectedData?.map(
                (element) => allTemplates[template][element]
            );

            setArrTemplateID(arr);
        }
    }, [template, languageSelectedData, templateID, draftFlag]);

    useEffect(() => {
        //creating array of factories
        if (Array.isArray(FactoryList)) {
            const codeArray = FactoryList.map((factory) => factory.Code);

            setFactoryData(codeArray);
        } else {
            console.error("FactoryList is not an array.");
        }
    }, [FactoryList]);

    const getSelectedID = () => {
        const departmentObject = {};

        Object.keys(departmentSelectedData).forEach((department) => {
            const selectedSubDepartments = departmentSelectedData[department]
                .filter((item) => item.isChecked || item.isSubDep)
                .map((item) => item.SubDepartment);

            if (selectedSubDepartments.length > 0) {
                departmentObject[department] = selectedSubDepartments;
            }
        });
        setselectedDeptID(departmentObject);
    };
    useEffect(() => {
        if (factorySelectedData.length === 0) {
            setAllDepartments([]);
        }
    }, [factorySelectedData]);

    useEffect(() => {
        getSelectedID();
    }, [departmentSelectedData]);

    useEffect(() => {
        {
            factorySelectedData.length > 0 && getDepartmentValues();
        }
    }, [getDepartmentFlag, factorySelectedData]);

    const getDepartmentValues = async () => {
        try {
            const result = [];
            // Fetching the ids of selected factories
            if (Array.isArray(FactoryList)) {
                FactoryList.forEach((item) => {
                    if (factorySelectedData.includes(item.Code)) {
                        result.push(item.id);
                    }
                });
            }

            // API call to get departmentData based on factory selected
            setFactoryListIds(result);

            const res = await getDepartmentData(result);

            setAllDepartments(res.data?.message_body?.departments);
        } catch (error) {
            // Handle API call errors
            console.error("Error fetching department data:", error);
        }
    };

    const submitHandler = () => {
        const selectedDate = dayjs(form.getFieldValue("date")).format(
            "YYYY-MM-DD"
        ); // Get and format the Date value
        const selectedTime = dayjs(form.getFieldValue("time")).format(
            "HH:mm:ss"
        ); // Get and format the Time value

        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/template/`
        );
        let departmentParam = "";
        if (Object.keys(selectedDeptID).length > 0) {
            // Convert the object to a JSON string and encode it
            departmentParam = JSON.stringify(selectedDeptID);

            queryUrl.searchParams.append("department", "yes");
        }

        queryUrl.searchParams.append("gender", genderSelectedData);
        queryUrl.searchParams.append("language", languageSelectedData);
        queryUrl.searchParams.append("templateID", arrTemplateID);
        queryUrl.searchParams.append("factory", factoryListIds);
        queryUrl.searchParams.append("Date", selectedDate);
        queryUrl.searchParams.append("Time", selectedTime);
        {
            draftFlag &&
                queryUrl.searchParams.append(
                    "draftID",
                    preFilledDraftData?.draftID
                );
        }
        try {
            if (
                Object.keys(selectedDeptID).length > 0 &&
                genderSelectedData.length > 0 &&
                arrTemplateID.length > 0 &&
                languageSelectedData
            ) {
                postTemplateData(queryUrl, departmentParam).then((res) => {
                    setVariableData(res.data.message_body);
                    setPrefilledInputValue(res.data.message_body.InputValue);
                    setActiveTab("2");
                    setTabState((prevState) => ({
                        ...prevState,
                        variable: {
                            ...prevState.variable,
                            disabled: "true",
                        },
                    }));
                });
            } else {
                alert("Fill all the fields");
            }
        } catch (error) {
            console.log("error in res");
        }
    };
    const onChangeTime = (time, timeString) => {
        if (timeString) {
            form.setFieldsValue({ timeString: timeString, time: time });
        }
    };
    const handleCalendarChange = (times) => {
        if (times) {
            setClickedTimes(times);
        }
    };

    return (
        <div
            style={{ minHeight: "50vh" }}
            onClick={() => (
                setDepartmentDropdownShow(false),
                setGenderDropdownShow(false),
                setLangDropdownShow(false)
            )}>
            <Form
                onValuesChange={onFormLayoutChange}
                form={form}
                initialValues={initialValues}
                onFinish={submitHandler}>
                <Row
                    style={{ padding: "3rem 1rem", minHeight: "50vh" }}
                    justify="space-around"
                    gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Template"
                            name="template"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a template!",
                                },
                            ]}>
                            {draftFlag ? (
                                <>
                                    <span>{preFilledDraftData?.Template}</span>
                                </>
                            ) : (
                                <Select placeholder="Select a template">
                                    {Object.keys(allTemplates)?.map((item) => {
                                        return (
                                            <Select.Option
                                                key={item}
                                                value={item}>
                                                {item}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a language!",
                                },
                            ]}>
                            <MultiSelectDropDown
                                draftFlag={draftFlag}
                                dropdownShow={langDropdownShow}
                                setDropdownShow={setLangDropdownShow}
                                labelData={
                                    Object.keys(allTemplates).length &&
                                    template &&
                                    Object.keys(allTemplates[template])
                                }
                                disabled={template?.length > 0 ? false : true}
                                selectedData={languageSelectedData}
                                setSelectedData={setLanguageSelectedData}
                                showConfirmation={false}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a gender!",
                                },
                            ]}>
                            <MultiSelectDropDown
                                dropdownShow={genderDropdownShow}
                                setDropdownShow={setGenderDropdownShow}
                                labelData={gender}
                                disabled={false}
                                selectedData={genderSelectedData}
                                setSelectedData={setGenderSelectedData}
                                showConfirmation={false}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Factory"
                            name="factory"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a factory!",
                                },
                            ]}>
                            <MultiSelectDropDown
                                dropdownShow={factoryDropdownShow}
                                setDropdownShow={setFactoryDropdownShow}
                                labelData={factoryData}
                                disabled={false}
                                selectedData={factorySelectedData}
                                setSelectedData={setfactorySelectedData}
                                showConfirmation={true}
                                setUpdateFlag={setGetDepartmentFlag}
                                updateFlag={getDepartmentFlag}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Date"
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a date!",
                                },
                            ]}>
                            <DatePicker
                                onChange={onChangeDate}
                                style={{ width: "100%" }}
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Time"
                            name="time"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a time!",
                                },
                            ]}>
                            <TimePicker
                                style={{ width: "100%" }}
                                use12Hours
                                format="h:mm a"
                                onChange={onChangeTime}
                                onCalendarChange={handleCalendarChange}
                                minuteStep={15}
                                disabledTime={disabledTime}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Departments"
                            name="department"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a department!",
                                },
                                {
                                    validator: (_, value) => {
                                        const departmentValue =
                                            form.getFieldValue("department");
                                        if (
                                            Object.keys(
                                                JSON.parse(departmentValue)
                                            ).length > 0
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "Please select a department!"
                                            )
                                        );
                                    },
                                },
                            ]}>
                            <MultiSubSelectDropDown
                                draftFlag={draftFlag}
                                preFilledDraftData={
                                    preFilledDraftData?.subDepartments
                                }
                                dropdownShow={departmentDropdownShow}
                                setDropdownShow={setDepartmentDropdownShow}
                                labelData={
                                    allDepartments
                                        ? allDepartments
                                        : preFilledDraftData?.subDepartments
                                }
                                selectedData={departmentSelectedData}
                                setSelectedData={setDepartmentSelectedData}
                                selectedDeptID={selectedDeptID}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div>
                    <hr />
                </div>
                <Row justify="space-between">
                    <Col style={{ padding: "1%" }}>
                        <span style={{ color: "red" }}>*</span> means mandatory
                    </Col>
                    <Row justify="end">
                        <Col>
                            {draftFlag && (
                                <button
                                    className="secondaryButton"
                                    onClick={() => showModal()}>
                                    Back
                                </button>
                            )}
                        </Col>
                        <Col>
                            <button className="primaryButton" type="submit">
                                Submit
                            </button>
                        </Col>
                    </Row>
                </Row>
            </Form>
            <Modal
                title="Changes not saved"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <p>
                    The changes you made on this screen are not saved. Are you
                    sure you want to go back?
                </p>
            </Modal>
        </div>
    );
};

export default Basic;
