/* Component with all Case reporter, Case Manager and Case Troubleshooter forms based on conditional rendering */
import React, { useState } from "react";
import CaseDetailsGeneral from "./CaseDetailsGeneral/";
import styles from "../CaseReport.module.css";

import CaseClosing from "./CaseClosing";
import { Modal, message, Divider, Collapse, Spin } from "antd";
import { useNavigate, useParams } from "react-router";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";
import { ClosingState } from "../../../RecoilState/ClosingState";

import {
    putCaseData,
    ResolveCaseByCT,
    saveClosingCaseReportCall,
    draftClosingCaseReportCall,
} from "../../../Adapters/CaseReportCalls";
import { ReopenCaseCTBySLA } from "../../../Adapters/CaseReportCalls";
import { useEffect } from "react";
import { tokenState } from "../../../RecoilState/tokenState";
import CaseReportDetails from "./CaseReportDetails";
import { caseReportDataState } from "../../../RecoilState/caseReportDataState";
import { FactoryData } from "../../../RecoilState/FactoryData";
import { getCounterFromCaseStatus } from "../../../hooks/getCounterFromCaseStatus";
import { previousRouteData } from "../../../RecoilState/previousRouteData";
import useEventMetricsUpdater from "../../../hooks/useEventMetricsUpdater";
import { getCaseDetailsByID } from "../../../Adapters/CaseCRUDCalls";
import QCForm from "../FeedbackReport/QCForm";
import { QCCaseReportDataState } from "../../../RecoilState/QCCaseReportDataState";
import { getQCcaseReport } from "../../../Adapters/QCcalls";
import AcknowledgementMessage from "./AcknowledgementMessage";
import { SendMessageFlag } from "../../../RecoilState/SendMessageFlag";

const key = "generalcaserep";
export default function GENERALcomponent(caseData) {
    let navigate = useNavigate();

    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);

    const [RAFieldflagValues, setRAFieldflagValues] = useState({
        closeModalFlag: false, //to show close modal for particular template
        closingRemarkMandatory: false, //to set all the fields of "closing remark" for special cases as mandatory and non mandator for RA
        CBRRMandatory: false, //to set "remark"- Comment by RA filed for Posh cases as mandatory and non mandator
    });
    const [conditionalNaviagte, setConditionalNaviagte] = useState("/home");
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    const [token, setToken] = useRecoilState(tokenState);
    // const [closingReportExist, setClosingReportExist] = useState(false);
    const [caseDatastate, setcaseDatastate] =
        useRecoilState(caseReportDataState);
    const [factoryList, setFactoryList] = useRecoilState(FactoryData);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [translatedDataObj, setTranslatedDataObj] = useState({});
    const params = useParams();
    let id = params?.caseID;
    const [readMode, setReadMode] = useState(true);
    const [openCompleteAction, setOpenCompleteAction] = useState(false);
    const [QCcaseDatastate, setQCcaseDatastate] = useRecoilState(
        QCCaseReportDataState
    );
    const [highlights, setHighlights] = useState({
        Exception: [],
        Prefilled: [],
    });
    const [sendMessageFlag, setSendMessageFlag] =
        useRecoilState(SendMessageFlag);
    const storedCriticalValue = localStorage.getItem("critical") === "true";
    //using the factory list to get the inache no of the particular factory, which the case belongs
    const fact =
        Array.isArray(factoryList) && factoryList.length
            ? factoryList
                  .map((val) => {
                      if (val.id === caseDatastate.Factory) {
                          return val.Number;
                      }
                  })
                  .filter(Boolean)
            : [];

    //getting the factory details from the getAllFactoriesOfCompany
    // Factory API call

    useEffect(() => {
        caseData?.Category?.workflow === "Poshratroubleshootflow" ||
        caseData?.Category?.workflow === "Ratroubleshootflow"
            ? setConditionalNaviagte(previousRoute.defaultRoute)
            : setConditionalNaviagte(previousRoute.defaultRoute);
    }, [caseDatastate.CaseCategory]);

    useEffect(() => {
        const fetchData = async () => {
            if (token && id) {
                const qcCaseReportResponse = await getQCcaseReport(id);
                setQCcaseDatastate(qcCaseReportResponse.data);
                const newHighlights = extractHighlights(
                    qcCaseReportResponse.data,
                    user.role
                );
                setHighlights(newHighlights);
            }
        };

        fetchData();
    }, [token, id, sendMessageFlag, caseData]);
    const [Manager, setManager] = React.useState(
        caseDatastate["CaseManager"] ? caseDatastate["CaseManager"] : ""
    );
    const [TroubleShooter, setTroubleShooter] = React.useState(
        caseDatastate["CaseTroubleShooter"]
            ? caseDatastate["TroubleShooter"]
            : ""
    );
    const [ClosingReport, setClosing] = useRecoilState(ClosingState);

    // MODAL LOGIC

    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const [isCloseModalVisible, setIsCloseModalVisible] = useState(false);
    const [isDraftModalVisible, setIsDraftModalVisible] = useState(false);
    const [isResetModalVisible, setIsResetModalVisible] = useState(false);

    const [isResolveModalVisible, setIsResolveModalVisible] = useState(false);
    const [isClosingtheCaseModalVisible, setIsClosingtheCaseModalVisible] =
        useState(false);
    const [isReopenModalVisible, setIsReopenModalVisible] = useState(false);

    function ReopenCTCase() {
        ReopenCaseCTBySLA(caseDatastate.id).then((res) => {
            message.success(res.data.response);
            navigate(conditionalNaviagte);
        });
    }
    const handleOkReopenModal = () => {
        ReopenCTCase();
        setIsReopenModalVisible(false);
    };
    const handleCancelReopen = () => {
        setIsReopenModalVisible(false);
    };

    function handleDraftButtonCT() {
        {
            draftClosingCaseReportCall(
                ClosingReport,
                caseDatastate?.id,
                caseDatastate.Company
            )
                .then((res) => {
                    if (res.status === 200) {
                        message.success(res.data);
                        navigate(conditionalNaviagte);
                    } else if (res.status === 201) {
                        message.success(res.data.message);
                        navigate(conditionalNaviagte);
                    } else {
                        message.warning(res.data);
                    }
                    updateEventMetrics(
                        "caseDashboardSaveDraft",
                        "Case Dashboard- Save draft"
                    );
                })
                .catch((error) => {
                    //handeling the error if the errorMessag is not coming from backend
                    error?.response?.data?.errorMessage
                        ? message.warning(error?.response?.data?.errorMessage)
                        : message.warning(
                              "Unable to save draft closing report ! Try again later"
                          );
                });
        }
    }
    function handleCloseButtonCT() {
        ResolveCaseByCT(caseDatastate.id).then((res) => {
            res.data
                ? message.success(res.data)
                : message.success("The case is closed now !");
            navigate(conditionalNaviagte);
        });
    }
    const showModalClose = () => {
        setIsCloseModalVisible(true);
    };
    const hideModalClose = () => {
        setIsCloseModalVisible(false);
    };
    const showModalDraft = () => {
        setIsDraftModalVisible(true);
    };
    const hideModalDraft = () => {
        setIsDraftModalVisible(false);
    };
    const handleOkDraftModal = () => {
        handleDraftButtonCT();
        hideModalDraft();
    };

    const handleOkCloseModal = () => {
        handleCloseButtonCT();
        hideModalClose();
    };
    const handleCancelOfClose = () => {
        hideModalClose();
    };
    const hideModalReset = () => {
        setIsResetModalVisible(false);
    };
    const handleOKresetModal = () => {
        setIsResetModalVisible(false);
    };

    const showModalResolve = () => {
        setIsResolveModalVisible(true);
    };
    const showClosingtheCaseModal = () => {
        setIsClosingtheCaseModalVisible(true);
    };

    const handleOkResolveModal = () => {
        // submit reslove
        ResolveCase();
        setIsResolveModalVisible(false);
    };
    function ResolveCase() {
        if (
            (ClosingReport.who === "" ||
                ClosingReport.what === "" ||
                ClosingReport.remarks === "" ||
                ClosingReport.when === "" ||
                ClosingReport.action === " " ||
                ClosingReport.isResolved === "") &&
            RAFieldflagValues.closingRemarkMandatory
        ) {
            message.warning(
                "Please fill the Closing Case Report Mandatory fields(*)"
            );
        } else if (
            (ClosingReport.CCRComments_RA === undefined ||
                ClosingReport.CCRComments_RA === "") &&
            RAFieldflagValues.CBRRMandatory
        ) {
            message.warning("Please fill the Remark Mandatory fields(*)");
        } else {
            saveClosingCaseReportCall(
                ClosingReport,
                caseDatastate?.id,
                caseDatastate.reopened,
                caseDatastate.Company,

                translatedDataObj
            )
                .then((res) => {
                    if (res?.Case) {
                        message.error("Case Closing Report already exists");
                    } else {
                        //set errro msg dynamic
                        message.success(res);
                        navigate(conditionalNaviagte);
                    }
                    updateEventMetrics(
                        "caseDashboardSubmit",
                        "Case Dashboard- Submit"
                    );
                })

                .catch((error) => {
                    //handeling the error if the errorMessag is not coming from backend
                    error?.response?.data?.errorMessage
                        ? message.warning(error?.response?.data?.errorMessage)
                        : message.warning(
                              "Unable to save closing report ! Try again later"
                          );
                });
        }
    }
    const handleCancelResolve = () => {
        setIsResolveModalVisible(false);
        setIsClosingtheCaseModalVisible(false);
    };

    const handleOkCancelModal = () => {
        setIsCancelModalVisible(false);
        navigate(previousRoute.defaultRoute);
    };

    const handleCancel = () => {
        setIsCancelModalVisible(false);
    };

    // const closingReportExistsFalse = () => {
    //     setClosingReportExist(false);
    // };

    // const closingReportExistsTrue = () => {
    //     setClosingReportExist(true);
    // };
    // MODAL LOGIC

    useEffect(() => {
        setLoading(true);
        if (token && caseData?.caseData?.id) {
            getCaseDetailsByID(caseData?.caseData.id)
                .then((res) => {
                    setcaseDatastate(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    }, [caseData.activeKey]);
    function checkCaseCategory(caseDatastate) {
        return caseDatastate?.Category?.workflow === "Poshratroubleshootflow";
    }
    const extractHighlights = (data, userRole) => {
        const highlights = data?.previous_qcreport?.reopenHighlights;
        if (userRole === "CR") {
            // Case Reporter
            return highlights?.CR;
        } else if (userRole === "CT") {
            // Case Troubleshooter
            return highlights?.CT;
        } else {
            return { Exception: [], Prefilled: [] };
        }
    };
    function saveDetailsandComments(values) {
        let data = {
            CaseNumber: caseDatastate.CaseNumber,
            Company: caseDatastate.Company,
            Factory: caseDatastate.Factory,
            ReportingMedium: caseDatastate.ReportingMedium,
            Version: caseDatastate.Version,
        };
        switch (user.role) {
            case "CR":
                // Mandatory fields are case details, priority, category, sub cat, case nature, case manager
                data.User = "CR";
                data.RegionalAdmin = values.regionalAdmin
                    ? values.regionalAdmin
                    : "";
                data.CommentsByRep = values.CommentsByRep;
                data.CaseDetails = values.caseDetails;
                data.priority = values.priority;
                data.Category = values.subCategory;
                // data.SubCategory = values.subCategory;
                data.CaseValidation = values.CaseValidation;
                data.CaseNature = values.CaseNature;
                data.CurrentStatus = "Submit";
                data.CaseManager = values.CaseManager ? values.CaseManager : "";
                data.workerLanguage = values.workersLanguage;
                message.loading({ content: "Loading...", key });

                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        {
                            message.success({
                                content: `Hey CR! Case is being ${res?.data?.CaseStatus}`,

                                key,
                            });
                        }

                        navigate(conditionalNaviagte);
                    })

                    .catch((error) => {
                        error?.response?.data?.errorMessage
                            ? message.error(error?.response?.data?.errorMessage)
                            : message.warning(
                                  "Unable to update Case manager ! Try again later"
                              );
                    });
                break;

            case "CM":
                //Mandatory fields are Case troubleshooter and comments
                data.priority = values.priority;
                data.Category = values.subCategory;
                // data.SubCategory = values.subCategory;
                data.RegionalAdmin = values.regionalAdmin;
                data.CommentsByMan = values.CommentsByMan;
                data.CaseValidation = values.CaseValidation;
                data.CaseNature = values.CaseNature;
                data.User = "CM";
                data.CurrentStatus = "Submit";
                data.CaseTroubleShooter = values.CaseTroubleShooter;

                message.loading({ content: "Loading...", key });

                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success({
                            content: `Hey CM ! Case is being ${res?.data?.CaseStatus}`,
                            key,
                        });
                        navigate(previousRoute.defaultRoute);
                    })
                    .catch((error) => {
                        error?.response?.data?.errorMessage
                            ? message.error(error?.response?.data?.errorMessage)
                            : message.warning(
                                  "Unable to update Case manager ! Try again later"
                              );
                    });
                break;
            case "CT":
                data.User = "CT";
                data.CurrentStatus = "Submit";
                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success("Hey CT, Case data is updated, ");
                        navigate(previousRoute.defaultRoute);
                    })
                    .catch((error) => {
                        error?.response?.data?.errorMessage
                            ? message.error(error?.response?.data?.errorMessage)
                            : message.warning(
                                  "Unable to update Case manager ! Try again later"
                              );
                    });
                break;
        }
    }

    function saveDraftDetailsandComments(values) {
        let data = {
            CaseNumber: caseDatastate.CaseNumber,
            Company: caseDatastate.Company,
            Factory: caseDatastate.Factory,
            ReportingMedium: caseDatastate.ReportingMedium,
            Version: caseDatastate.Version,
        };
        switch (user.role) {
            case "CR":
                data.User = "CR";
                data.CommentsByRep = values.CommentsByRep
                    ? values.CommentsByRep
                    : "";
                data.CaseDetails = values.caseDetails ? values.caseDetails : "";
                data.priority = values.priority ? values.priority : "";
                data.Category = values.subCategory ? values.subCategory : "";
                // data.SubCategory = values.subCategory ? values.subCategory : "";
                data.CaseValidation = values.CaseValidation;
                data.CaseManager = values.CaseManager ? values.CaseManager : "";
                data.RegionalAdmin = values.RegionalAdmin
                    ? values.RegionalAdmin
                    : "";

                data.CaseNature = values.CaseNature ? values.CaseNature : "";
                data.workerLanguage = values.workersLanguage
                    ? values.workersLanguage
                    : "";
                data.CurrentStatus = "Draft";
                message.loading({ content: "Loading...", key });

                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success({
                            content: "Hey CR ! Case data are updated",
                            key,
                        });

                        navigate(previousRoute.defaultRoute);
                    })
                    .catch((err) => {
                        message.warning(
                            "Unable to update Case Data ! Try again later"
                        );
                    });
                break;
            case "CM":
                data.priority = values.priority ? values.priority : "";
                data.CaseCategory = values.category ? values.category : "";
                data.RegionalAdmin = values.regionalAdmin;
                data.Category = values.subCategory ? values.subCategory : "";
                // data.SubCategory = values.subCategory ? values.subCategory : "";
                data.CommentsByMan = values.CommentsByMan
                    ? values.CommentsByMan
                    : "";
                data.CaseValidation = values.CaseValidation
                    ? values.CaseValidation
                    : "";
                data.CaseNature = values.CaseNature ? values.CaseNature : "";
                data.User = "CM";
                data.CaseTroubleShooter = values.CaseTroubleShooter
                    ? values.CaseTroubleShooter
                    : "";
                data.CurrentStatus = "Draft";
                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success({
                            content: "Hey CM ! Case data are updated",
                            key,
                        });
                        navigate(previousRoute.defaultRoute);
                    })
                    .catch((err) => {
                        message.warning(
                            "Unable to update Case Data ! Try again later"
                        );
                    });
                break;
            case "CT":
                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success({
                            content: "Hey CT ! Case data are updated",
                            key,
                        });
                        navigate(previousRoute);
                    })
                    .catch((err) => {
                        message.warning(
                            "Unable to update Case Data ! Try again later"
                        );
                    });
        }
    }
    function handleStateChangeOFTRB(value) {
        setTroubleShooter(value);
    }

    function showAckmsgComp(role) {
        const counter = getCounterFromCaseStatus(caseDatastate.CaseStatus);
        switch (role) {
            case "CT":
                return true;
            case "REGIONAL_ADMIN":
                if (storedCriticalValue) {
                    return (
                        caseDatastate?.Category?.workflow ===
                        "Ratroubleshootflow"
                    );
                } else {
                    return (
                        counter > 3 &&
                        QCcaseDatastate?.ackmsg &&
                        Object.keys(QCcaseDatastate?.ackmsg).length !== 0
                    );
                }
            case "CR":
            case "CM":
            case "SUPER_ADMIN":
            case "LEAD_SUPERVISOR":
                return (
                    counter > 3 &&
                    QCcaseDatastate?.ackmsg &&
                    Object.keys(QCcaseDatastate?.ackmsg).length !== 0
                );
            default:
                return true;
        }
    }
    return loading ? (
        <Spin></Spin>
    ) : (
        <>
            <CaseDetailsGeneral caseData={caseDatastate} />
            <Divider className={styles.caseRepDivider} />

            {QCcaseDatastate?.previous_qcreport && (
                <>
                    <QCForm
                        readOnly={readMode}
                        status={QCcaseDatastate?.previous_qcreport?.reportType}
                        openCompleteAction={openCompleteAction}
                        setOpenCompleteAction={setOpenCompleteAction}
                        QCcaseData={QCcaseDatastate?.previous_qcreport}
                        generalReport={true}
                    />
                </>
            )}

            <CaseReportDetails
                showWorkerLanguage={true}
                highlightedFields={highlights}
                caseDatastate={caseDatastate}
                setcaseDatastate={setcaseDatastate}
                saveDetailsandComments={saveDetailsandComments}
                saveDraftDetailsandComments={saveDraftDetailsandComments}
            />

            {showAckmsgComp(user.role) &&
                caseDatastate?.Complainer !== null && (
                    <div>
                        <AcknowledgementMessage
                            QCcaseDatastate={QCcaseDatastate?.ackmsg}
                        />
                    </div>
                )}
            <CaseClosing
                highlightedFields={highlights}
                caseData={caseDatastate}
                showModalDraft={showModalDraft}
                showModalClose={showModalClose}
                showModalResolve={showModalResolve}
                showClosingtheCaseModal={showClosingtheCaseModal}
                setIsReopenModalVisible={setIsReopenModalVisible}
                // closingReportExistsFalse={closingReportExistsFalse}
                // closingReportExistsTrue={closingReportExistsTrue}
                translatedDataObj={translatedDataObj}
                setTranslatedDataObj={setTranslatedDataObj}
                setRAFieldflagValues={setRAFieldflagValues}
                disableResolvingReport={
                    caseDatastate?.Complainer !== null &&
                    !checkCaseCategory(caseDatastate)
                        ? QCcaseDatastate?.ackmsg?.timestamp
                            ? false
                            : true
                        : false
                }
                isCTResolveCase={
                    getCounterFromCaseStatus(caseDatastate.CaseStatus) >= 6 ||
                    checkCaseCategory(caseDatastate)
                }
            />

            <Modal
                title="Cancel"
                visible={isCancelModalVisible}
                onOk={handleOkCancelModal}
                onCancel={handleCancel}>
                <p>Do you want to exit the case report ? </p>
            </Modal>
            <Modal
                title="Draft"
                visible={isDraftModalVisible}
                onOk={handleOkDraftModal}
                onCancel={hideModalDraft}>
                <p>Do you want to save the case as Draft?</p>
            </Modal>
            {/* CT and RA closing the cases which have compainer */}
            <Modal
                title="Please Confirm"
                visible={isResolveModalVisible}
                onOk={handleOkCloseModal}
                onCancel={handleCancelResolve}>
                <p>
                    As per the worker’s response marked- <b>Satisfied</b>, the
                    case will be <b>Closed</b>. Are you sure you want to Close
                    the case?
                </p>
            </Modal>
            {/* CT and RA reopening the cases which have compainer */}
            <Modal
                title="Please Confirm"
                visible={isReopenModalVisible}
                onOk={handleOkReopenModal}
                onCancel={handleCancelReopen}>
                <p>
                    As per the worker’s response marked- <b>Unsatisfied</b>, the
                    case will be <b>Reopened</b>. Are you sure you want to
                    Reopen the case?
                </p>
            </Modal>
            {/* CT and RA closing the cases which dosent have compainer */}
            <Modal
                title="Please Confirm"
                visible={isClosingtheCaseModalVisible}
                onOk={handleOkCloseModal}
                onCancel={handleCancelResolve}>
                <p>
                    The case will be <b>Closed</b>. Are you sure you want to
                    Close the case?
                </p>
            </Modal>
            <Modal
                title="Reset"
                visible={isResetModalVisible}
                onOk={handleOKresetModal}
                onCancel={hideModalReset}>
                <p>Do you want to clear Input data ?</p>
            </Modal>
            <Modal
                title={
                    RAFieldflagValues.closeModalFlag ||
                    (user.role === "CT" && caseDatastate.Complainer === null)
                        ? "Close"
                        : "Resolve"
                }
                visible={isCloseModalVisible}
                onOk={handleOkResolveModal}
                onCancel={handleCancelOfClose}>
                <p>
                    {RAFieldflagValues.closeModalFlag ||
                    (user.role === "CT" && caseDatastate.Complainer === null)
                        ? "Do you want to Close the Case ?"
                        : "Do you want to Resolve Case ?"}
                </p>
            </Modal>
        </>
    );
}
